form {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.label,
label {
    font-size: 16px;
    line-height: 18px;
    color: var(--dark-blue);
    font-weight: 300;
    margin-bottom: 5px;

    span {
        color: var(--magenta);
    }
}
input,
textarea {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;

    font-weight: 300;
    font-size: 24px;
    line-height: 26px;

    border: 1px solid var(--grey-200);
    border-radius: 10px;

    transition: all 75ms ease-in-out;

    &:focus {
        border: 1px solid var(--grey-400);
        outline: none;
    }
}
textarea {
    resize: none
}

.error-message {
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    color: var(--magenta);

    margin-top: 5px;
}


.field-wrapper {
    display: flex;
    flex-direction: column;
    
    width: 100%;
    margin-bottom: 15px;

    &.row-wrapper {
        flex-direction: row;
    }
}


//
// Checkbox
//
.check-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
  
    margin: 5px 0;
    padding: 0;
    cursor: pointer;
  
    input {
        position: absolute;
        pointer-events: none;
        opacity: 0;
        height: 0;
        width: 0;
    
        // &:focus,
        &:focus-visible,
        &:active {

            &:checked {
                + .checkbox {
                    background: var(--lite-blue);
                }
            }

            + .checkbox {
                background: var(--grey-100);

                span {
                    border-bottom: 2px solid var(--dark-blue);
                    border-right: 2px solid var(--dark-blue);
                }
            }
        }

        &.required {
        ~ .checkbox {
            border: 2px solid var(--magenta) !important;
            }
        }

        &:checked {
            ~ .checkbox {
                background: var(--dark-blue);
                border: 2px solid var(--dark-blue-variant);

                span {
                    border-bottom: 2px solid var(--white);
                    border-right: 2px solid var(--white);
                }
            }
        
        }
    }
  
    .checkbox {
        margin: 0 10px 0 0;
  
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
  
        height: 24px;
        width: 24px;
        margin: 0;
        background: var(--grey-100);
        border: 2px solid var(--grey-200);
        border-radius: 3px;
  
        transition: all 75ms ease-in-out;
  
        span {
            display: block;
            height: 11px;
            width: 6px;
            background: var(--clear);
  
            background: transparent;
            border-bottom: 2px solid var(--clear);
            border-right: 2px solid var(--clear);
            transform: rotate(45deg);
            margin-top: -3px;
  
            transition: all 75ms ease-in-out;
        }
    }
  
    .label {
        margin: 5px 0 0 8px;
    }
  
    &:hover {
        outline: none;

        input {
            &:checked {
                ~ .checkbox {
                }
            }
        }
  
        .checkbox {
          background: var(--grey-100);
  
            span {
                border-bottom: 2px solid var(--dark-blue);
                border-right: 2px solid var(--dark-blue);
            }
        }
    }
  }