:root {
    --clear: transparent;
    --white: #FFFFFF;
    --black: #000000;

    --white-op-25: rgba(255, 255, 255, 0.25);
    
    --black-op-25: rgba(0, 0, 0, 0.25);

    --dark-blue: #001A29;
    --dark-blue-variant: #02324E;
    --lite-blue: #385C70;
    
    --magenta: #D233A5;
    --aqua: #36FFFF;
    --orange: #FF6421;

    --grey-100: #ebebeb;
    --grey-200: #dbd8d8;
    --grey-300: #c7c6c6;
    --grey-400: #aeacac;
}