::selection{
  background: var(--magenta);
  color: var(--white);
}

.geologica, * {
  font-family: "Geologica", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500; // 100 to 900
  font-style: normal;
  font-variation-settings:
    "slnt" 0,
    "CRSV" 0,
    "SHRP" 0;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    color: var(--dark-blue);
}

p {
  color: var(--lite-blue);
}