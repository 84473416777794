@import "_reset.scss";
@import "_colors.scss";
@import "_fonts.scss";
@import "_forms.scss";

html,
body {
    // height: 100%;
    width: 100%;
    background: var(--dark-blue);

    scroll-behavior: smooth;
}

body {
    overflow-x: hidden;
}

// 
// Page Styles
//
.page-banner {
    height: 420px;

    ::selection{
        background: var(--magenta);
        color: var(--white);
    }

    .banner-container {
        height: 100%;
        padding: 20px 20px 100px;
        box-sizing: border-box;
        max-width: 1400px;
        margin: 0 auto;

        display: flex;
        justify-content: flex-end;
        flex-direction: column;

        border-right: 1px solid var(--dark-blue-variant);
        border-left: 1px solid var(--dark-blue-variant);
        
        @media (min-width: 520px) {
            padding: 20px 50px 100px;
        }
    }

    h1 {
        font-size: 36px;
        line-height: 38px;
        margin-bottom: 5px;

        font-weight: 900;
        text-transform: uppercase;
        color: var(--white);
        hyphens: auto;

        background-color:hsla(316,63%,51%,1);
        background-image:
        radial-gradient(at 83% 17%, hsla(180,100%,60%,1) 0px, transparent 50%),
        radial-gradient(at 49% 55%, hsla(316,63%,51%,1) 0px, transparent 50%),
        radial-gradient(at 0% 100%, hsla(18,100%,56%,1) 0px, transparent 50%),
        radial-gradient(at 90% 93%, hsla(241,100%,70%,1) 0px, transparent 50%),
        radial-gradient(at 0% 0%, hsla(316,63%,51%,1) 0px, transparent 50%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        // @media (max-width: 370px) { 
            word-break: break-word;
        // }

        @media (min-width: 380px) { 
            font-size: 46px;
            line-height: 48px;
        }
        @media (min-width: 1040px) { 
            font-size: 52px;
            line-height: 54px;
            margin-bottom: 10px;
        }

        &::selection{
            // background: var(--clear);
            -webkit-text-fill-color: var(--white);
            color: var(--white);
        }
    }
    p {
        font-size: 16px;
        line-height: 24px;
        color: var(--white);

        max-width: 1120px;

        @media (min-width: 1040px) { 
            font-size: 20px;
            line-height: 28px;
        }
    }
}
.page-content {
    background: var(--white);
    padding: 0;

    .page-wrapper {
        height: 100%;
        padding: 50px 20px 75px;
        box-sizing: border-box;
        max-width: 1400px;
        margin: 0 auto;

        display: flex;
        flex-direction: row;

        border-right: 1px solid var(--grey-100);
        border-left: 1px solid var(--grey-100);
        
        @media (min-width: 450px) {
            padding: 50px;
        }
        @media (min-width: 720px) {
            padding: 100px 50px;
        }

        &.column-wrapper {
            flex-direction: column;
        }

        &.service-wrapper {
            padding: 20px 15px;

            @media (min-width: 520) {
                padding: 50px;
            }
            @media (min-width: 780px) {
                padding: 100px 0px 100px 50px;
            }
        }
    }

    .full-width-banner {

        &.dark-banner {
            background: var(--dark-blue);
        }
        &.magenta-banner {
            background: var(--magenta);
        }
    }
}

//
// Inner Page Styles
//

// Sticky Bar
.sticky-bar {
    flex-shrink: 0;
    
    position: relative;
    width: 225px;

    @media (max-width: 779px) {
        display: none;
    }

    .sticky-list {
        display: flex;
        flex-direction: column;
        width: 225px;

        &.fixed {
            position: fixed;
            top: 50px;
        }
        &.absolute {
            position: absolute;
            top: inherit;
            bottom: 0;
        }
    }

    p {
        font-size: 16px;
        line-height: 18px;
        text-decoration: none;
        color: var(--dark-blue);

        cursor: pointer;

        padding: 8px 12px;
        background: var(--clear);
        margin-bottom: 5px;

        // transition: all 125ms ease-in-out;

        &.active {
            border-radius: 5px;
            // background: rgb(0,0,0);
            background: linear-gradient(90deg, hsla(18,100%,56%,1) 0%, hsla(316,63%,51%,1) 31%, hsla(180,100%,60%,1) 100%);
            background-size: 150%;

            color: var(--white);
        }
    }
}
// Info Col + Content
.info-column {
    width: 100%;

    @media (min-width: 780px) {
        margin: 0 0 0 100px;
    }
}
.info-list {
    display: flex;
    flex-direction: column;
}
.info-item {
    display: flex;
    flex-direction: row;

    padding: 25px 20px 75px 0;
    border-bottom: 1px solid var(--grey-100);
    scroll-margin-top: 40px;

    @media (min-width: 520px) {
        padding: 25px 50px 100px 0;
    }
    @media (min-width: 1040px) {
        padding: 25px 50px 150px 0;
    }

    &:last-child {
        border-bottom: 0px solid var(--grey-100);
    }

    &.active {

        .item-number {
            color: var(--magenta);
        }
    }

    .item-number {
        font-size: 52px;
        line-height: 52px;
        font-weight: 900;
        color: var(--dark-blue);
        text-align: right;

        width: 100px;

        transition: all 125ms ease-in-out;
    }

    .item-content {
        display: flex;
        flex-direction: column;
        margin: 0 0 0 20px;
    }

    h2 {
        font-size: 26px;
        line-height: 32px;
        font-weight: 300;
        text-transform: uppercase;
        
        margin-bottom: 5px;

        @media (min-width: 1040px) {
            font-size: 32px;
            line-height: 38px;
        }
    }
    p {
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
    }
}

// 
// Large Conact Button
//
.contact-cta {
    height: 100%;
    padding: 50px 20px 100px;
    box-sizing: border-box;
    max-width: 1400px;
    margin: 0 auto;

    display: flex;
    flex-direction: column;

    border: 1px solid var(--grey-100);
    border-bottom: 0px solid var(--grey-100);
    
    @media (min-width: 520px) {
        padding: 75px 50px;
    }
    
    @media (min-width: 1040px) {
        padding: 100px 50px;
    }

    p {
        font-size: 16px;
        line-height: 22px;
        text-align: center;

        width: 100%;
        max-width: 720px;
        margin: 0 auto;

        @media (min-width: 720px) {
            font-size: 20px ;
            line-height: 30px ;
        }
    }

    .button,
    button {
        font-size: 36px;
        line-height: 46px;

        width: 290px;
        border-radius: 200px;
        padding: 20px 25px;

        margin: 25px auto 0;

        @media (min-width: 520px) {
            font-size: 55px;
            line-height: 65px;
    
            width: 420px;
            border-radius: 200px;
            padding: 45px 55px;
    
        }
        @media (min-width: 720px) {
            font-size: 75px;
            line-height: 85px;
    
            width: 600px;
            border-radius: 200px;
            padding: 65px 85px;

            margin: 50px auto 0;
        }

        @media (min-width: 1080px) {
            font-size: 100px;
            line-height: 120px;

            width: 810px;
            padding: 100px 150px;
        }
    }
}

.button,
button {
    font-family: "Geologica", sans-serif;
    font-optical-sizing: auto;
    font-weight: 900;
    font-style: normal; // Available: normal, italic (only 800)
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    text-decoration: none;
    color: var(--white);
  
    cursor: pointer;
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  
    padding: 12px 20px;
    border-radius: 50px;
    position: relative;
  
    transition: box-shadow 75ms ease-in-out;
  
    // Safari Override
    -webkit-appearance: none !important;
    // BS Override — remove BS TODO
    border: none !important;
    outline: none !important;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.3);

    background: linear-gradient(90deg, hsla(18,100%,56%,1) 0%, hsla(316,63%,51%,1) 31%, hsla(180,100%,60%,1) 100%);
    background-size: 150%;

    @media (hover: hover) {
        &:hover {
            color: var(--white);
            box-shadow: 0px 4px 5px rgba(0,0,0,0.3);
        }
    }

    &:disabled,
    &.disabled {
        pointer-events: none !important;
        opacity: 0.5 !important;
    }

    svg {
        height: 20px;
        width: 20px;
        margin-left: 10px;

        display: block;
        fill: var(--white);
    }
}

.arrow-link {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    text-decoration: none;
    color: var(--white);
    font-size: 24px;
    line-height: 24px;
    font-weight: 700;

    padding: 5px 0;
    margin-top: 10px;

    svg {
        height: 22px;
        width: 26px;
        fill: var(--white);
        margin: 3px 0 0 5px;

        transform: rotate(90deg);
    }
}


// @media (hover: hover) {}
// @media (max-width: 767px) { }